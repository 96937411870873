import "./App.css";
import abi from "./utils/NFTArtCreator.json";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ethers } from "ethers";
import { useSearchParams } from "react-router-dom";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const contractAddress = "0x3bcDbcD8Bb219F3FB6c56511A03bb99ec33d638f";
  const contractABI = abi;
  const [currentAccount, setcurrentAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);
  const nftName = useRef(null);
  const nftDesc = useRef(null);
  const paramName = searchParams.get("name");
  const paramDescription = searchParams.get("description");
  const paramImg = searchParams.get("img");
  const [isProcessing, setIsProcessing] = useState(false);
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  useEffect(() => {
    nftName.current.value = paramName;
    nftDesc.current.value = paramDescription;

    if (paramImg) {
      const image = document.querySelector(".nft-img");
      image.src = `https://nft-art-creator-api.herokuapp.com/file/${paramImg}`;
    }
  }, []);

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          accountChangedHandler(result[0]);
          document.querySelector(".conn-text").innerHTML =
            "Successful. Complete Minting";
          const connBtn = document.querySelector(".conn-btn");
          connBtn.classList.add("conn-btn-active");
          connBtn.innerHTML =
            '<img src="done.svg" alt="">' +
            result[0].substring(0, 5) +
            "..." +
            result[0].substring(result[0].length - 5, result[0].length);
          document.querySelector(".mint-btn").classList.add("mint-btn-active");
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("please install metamask");
    }
  };

  const accountChangedHandler = async (newAccout) => {
    setcurrentAccount(newAccout);
    updateEthers();
  };

  const updateEthers = () => {
    let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
    setProvider(tempProvider);

    let tempSigner = tempProvider.getSigner();
    setSigner(tempSigner);

    let tempContract = new ethers.Contract(
      contractAddress,
      contractABI,
      tempSigner
    );
    setContract(tempContract);
  };

  const inputValidationCheck = () => {
    const nameInput = nftName.current.value;
    const descInput = nftDesc.current.value;

    if (paramImg && nameInput !== "" && descInput !== "") {
      return true;
    } else {
      return false;
    }
  };

  const showFeedback = (message) => {
    document.querySelector(".feedback-cont").style.display = "flex";
    document.querySelector(".feedback-txt").innerHTML = message;
  };

  const hideFeedback = () => {
    document.querySelector(".feedback-cont").style.display = "none";
  };

  const hideSuccess = () => {
    document.querySelector(".success-cont").style.display = "none";
  };

  const mintHandler = async () => {
    if (currentAccount === "") {
      return connectWalletHandler();
    }

    if (window.ethereum.networkVersion != 1) {
      return showFeedback("Please switch your network to ethereum mainnet.");
    }

    if (!inputValidationCheck()) {
      return showFeedback("Please fill all the required spaces.");
    }

    setIsProcessing(true);

    let imgValid;

    await axios({
      method: "get",
      url: `https://nft-art-creator-api.herokuapp.com/file/${paramImg}`,
    }).then(async (res) => {
      const data = await res.data;
      if (data == "not found") {
        imgValid = false;
      }
    });

    if (imgValid == false) {
      setIsProcessing(false);
      return showFeedback(
        "An error occured, please try again after a few minutes."
      );
    }

    const metadata = {
      name: nftName.current.value,
      description: nftDesc.current.value,
      image: "",
    };

    let hashes = [];

    try {
      await axiosInstance({
        method: "post",
        url: `/file/pin/${paramImg}`,
        data: metadata,
      }).then(async (res) => {
        const data = await res.data;
        hashes = [data[0], data[1]];
        console.log(hashes);
        const tx = await contract.safeMint(data[1]);
        document.querySelector(".success-cont").style.display = "flex";
        document.querySelector(
          ".etherscan-link"
        ).href = `https://etherscan.io/tx/${tx.hash}`;
        setIsProcessing(false);
      });
    } catch (error) {
      setIsProcessing(false);
      console.log(error);

      await axiosInstance({
        method: "delete",
        url: `/file/unpin/${hashes[0]}/${hashes[1]}`,
      }).then(async (res) => {
        console.log(res.data);
      });
    }
  };

  return (
    <div className="App">
      <div className="feedback-cont">
        <div className="feedback">
          <p className="feedback-txt"></p>
          <div onClick={hideFeedback} className="close-btn">
            Close
          </div>
        </div>
      </div>
      <div className="success-cont">
        <div className="success-feedback">
          <div onClick={hideSuccess} className="close-succ">
            <img src="./x.svg" alt="" />
          </div>
          <img className="succ-bg" src="./success.svg" alt="" />
          <img className="pr-hand" src="./hand.svg" alt="" />
          <h2>Your NFT has been succesfully minted </h2>
          <p>
            You can check your NFT on OpenSea when the transaction is completed.
            (Est. time 3 min)
          </p>
          <p>
            Check the status of the transaction here:{" "}
            <a className="etherscan-link" target="_blank" href="">
              Etherscan
            </a>
          </p>
        </div>
      </div>
      <img className="header-logo" src="./logo-header.svg" alt="" />

      <h2 className="conn-text">Connect Your MetaMask wallet.</h2>
      <button onClick={connectWalletHandler} className="conn-btn">
        <img className="fox" src="fox.svg" alt="" /> Connect
      </button>
      {paramImg ? (
        <img className="nft-img" src="" alt="" />
      ) : (
        <div className="place-h">
          <img src="./placeholder.svg" alt="" />
          <a
            className="d-link"
            href="https://apps.apple.com/US/app/id1594322263?l=en"
            target="_blank"
          >
            Download
          </a>
        </div>
      )}
      <label htmlFor="nft-name">Title</label>
      <input
        type="text"
        name=""
        id="nft-name"
        placeholder="Crypto Apes"
        ref={nftName}
        autoComplete="off"
      />
      <br />
      <label htmlFor="nft-desc">Description</label>
      <input
        type="text"
        name=""
        id="nft-desc"
        placeholder="Cool Ape with blue outfit"
        ref={nftDesc}
        autoComplete="off"
      />
      <div onClick={mintHandler} className="mint-btn">
        {!isProcessing ? (
          "Mint & Create NFT"
        ) : (
          <>
            <img className="loading-circle" src="loading.svg" alt="" />
          </>
        )}
      </div>
    </div>
  );
}

export default App;
